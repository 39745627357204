import React, { useState } from 'react';
import axios from 'axios';

const CancelSubscription = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleCancelClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmCancel = async () => {
    setIsLoading(true);
    setMessage('');

    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/stripe/create-portal-session`, { mes: 'hello' }, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      setMessage(response.data.message || 'Your subscription has been canceled.');
    } catch (error) {
      setMessage('Failed to cancel subscription. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="cancel-subscription">
      <h2>Cancel Subscription</h2>
      <button className="cancel-button" onClick={handleCancelClick}>
        Cancel Subscription
      </button>

      {message && <p className="message">{message}</p>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Are you sure you want to cancel your subscription? Please be aware this will end your rewards.</h3>
            <button className="confirm-button" onClick={handleConfirmCancel} disabled={isLoading}>
              {isLoading ? 'Cancelling...' : 'Yes, Cancel Subscription'}
            </button>
            <button className="sub-close-button" onClick={handleCloseModal}>
              No, Keep Subscription
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CancelSubscription;
