import React from 'react';
import { FaTruck, FaHeadset, FaTag, FaGift, FaCalendar } from 'react-icons/fa';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen/index';
import CancelMembership from './CancelMembership';

const MembershipActive = () => {
    const cld = new Cloudinary({
        cloud: {
          cloudName: 'dtlf7hj7b',
        },
    });
    
    const mainImage = cld.image('Bstreet_o6qtwr.png');
  return (
    <div className="membership-active">
      <div className="membership-active-container">
        <div className="membership-active-header">
          <h1>Plus Perks Unlocked</h1>
          <AdvancedImage cldImg={mainImage} />
        </div>
        
        <div className="perks-list">
          <div className="perk-item">
            <FaTruck className="benefit-icon" />
            <p>Free delivery on all orders!</p>
          </div>
          <div className="perk-item">
            <FaHeadset className="benefit-icon" />
            <p>Priority customer support for faster assistance!</p>
          </div>
          <div className="perk-item">
            <FaTag className="benefit-icon" />
            <p>Exclusive samples from our partners!</p>
          </div>
          <div className="perk-item">
            <FaGift className="benefit-icon" />
            <p>Early access to new features and limited-time products!</p>
          </div>
          <div className="perk-item">
            <FaTruck className="benefit-icon" />
            <p>Rewards Streak!</p>
          </div>
          <div className="perk-item">
            <FaCalendar className="benefit-icon" />
            <p>Access to Secret Plus Day!</p>
          </div>
        </div>
      </div>
      <CancelMembership />
    </div>
  );
};

export default MembershipActive;
