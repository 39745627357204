import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const Favorites = () => {
  const [restaurantData, setRestaurantData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)'});

  const fetchFavorites = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/profile/favorites`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      setFavorites(response.data.favorites);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };

  const fetchBusinesses = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/restaurants`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      setRestaurantData(response.data.restaurants);
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  const handleSubmit = () => {}

  const favoriteRestaurants = restaurantData.filter(restaurant =>
    favorites.some(fav => fav.restaurantId === restaurant._id)
  );

  useEffect(() => {
    if(localStorage.getItem("authToken")){
      fetchFavorites();
      fetchBusinesses();
      window.scrollTo(0,0)
    }
  }, []);

  return (
    <div className='favorites'>
      <div className="favorites-container">
        {favoriteRestaurants.length > 0 ? (
          favoriteRestaurants.map(favorite => (
            <div className='favorite' key={favorite._id}>
              <img src={favorite.iconPhoto} alt={favorite.businessName + " " + 'logo'} />
              <div className="favorite-details">
                <h1>{favorite.businessName}</h1>
                {
                  isMobile ? null : 
                  <h2>{favorite.businessAddress.streetAddress} {favorite.businessAddress.city} {favorite.businessAddress.state} {favorite.businessAddress.zipCode}</h2>
                }
                {
                  isMobile ? null : <h2>{favorite.businessType}</h2>
                }
                {
                  isMobile ? null : <h2>{favorite.rating?.favorite.rating || "Rating coming soon"}</h2>
                }
              </div>
              <Link to={`/main/business/${favorite.businessName}`}><button className='favorite-details-button' onClick={handleSubmit}>Go to store</button></Link>
            </div>
          ))
        ) : (
          <p>You have no favorites</p>
        )}
      </div>
    </div>
  );
};

export default Favorites;
