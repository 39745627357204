import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaLocationArrow, FaUser, FaShoppingCart, FaDotCircle, FaCircle, FaExclamation, FaExclamationCircle } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { AdvancedImage } from "@cloudinary/react";

const SignedInDesktop = ({
  itemInCart,
  handleSubmitSearch,
  querySearchValue,
  handleChange,
  openSearch,
  handleOpenSearch,
  searchInput,
  businesses,
  hide,
  address,
  logo,
}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  return (
    <nav className="nav-bar">
      <div className="nav-bar-left">
        <div>
          <Link to="/main">
            <AdvancedImage className="signedIn-logo" cldImg={logo} />
          </Link>
        </div>
        <div className="nav-bar-address-container">
          <h2>
            {address && address.address ? (
              <>
                <FaLocationArrow /> {address.address.streetAddress}
              </>
            ) : (
              "Visit your profile to enter a delivery address"
            )}
          </h2>
        </div>
      </div>
      <div className="nav-bar-right">
        <div className="signedInSeach-container">
          {!hide && (
            <input
              value={querySearchValue || searchInput.signedInSearch}
              onChange={handleChange}
              onClick={() => handleOpenSearch(true)}
              placeholder="Search for food or any supplies you may need..."
              type="search"
              autoComplete="off"
              name="signedInSearch"
              id="signedInSeach"
            />
          )}
          {openSearch && (
            <div className="search-drop-down">
              <div className="search-drop-down-wrapper">
                <div
                  className="close-search"
                  onClick={() => handleOpenSearch(false)}
                >
                  <span>Close</span>
                </div>
                <div className="search-drop-down-wrapper-flex">
                  {businesses && searchInput.signedInSearch.length > 0 ? (
                    businesses
                      .filter((business) =>
                        business.businessName
                          .toLowerCase()
                          .includes(searchInput.signedInSearch.toLowerCase())
                      )
                      .slice(0, 6)
                      .map((business) => (
                        <Link
                          to={`/main/business/${business.businessName}`}
                          onClick={handleSubmitSearch}
                          className="search-bar-item"
                          key={business._id}
                        >
                          <img
                            src={business.iconPhoto}
                            alt={business.businessName}
                          />
                          <p>{business.businessName}</p>
                        </Link>
                      ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="nav-bar-user-options">
          <Link to="/profile">
            <FaUser />
          </Link>
          {!hide && (
            <div className="cart-animation">
              {itemInCart && <FaExclamationCircle className="circleExclamation" color="red" size={12} />}
              <Link to="/checkout">
                <FaShoppingCart />
              </Link>
            </div>
          )}
          <CiLogout onClick={handleLogout} />
        </div>
      </div>
    </nav>
  );
};

export default SignedInDesktop;
