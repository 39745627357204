import React, { useEffect, useState } from 'react'
import { FaHeadset, FaTag, FaTruck, FaGift, FaCalendar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MembershipActive from './MembershipActive';

const BStreetPlus = () => {
  const navigate = useNavigate();
  const [membershipStatus, setMembershipStatus] = useState(null);


  const checkMemberShipStatus = async() => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/profile/membership`, {
        headers: {
          "Authorization": `Bearer ${token}`, 
          "Content-Type": "application/json" 
        }});
        const {membershipStatus} = response.data;
        setMembershipStatus(membershipStatus);
    } catch (error) {
      console.log('There has been an error fetching your membership status')
    }
  };

  useEffect(()=>{
    if(!localStorage.getItem("authToken")){
      navigate("/");
    };
    checkMemberShipStatus();
    window.scrollTo(0,0);
  },[]);
  return (
    <div className='membership'>
      <div className="membership-container">
        {
          membershipStatus && membershipStatus.active  === false ? 
          <>
            <div className="membership-container-left">
              <h1>B-Street Plus</h1>
              <ul className='membership-container-perks'>
                <li>
                  <FaTruck className="benefit-icon" /> Free delivery on all orders!
                </li>
                <li>
                  <FaHeadset className="benefit-icon" /> Priority customer support for faster assistance!
                </li>
                <li>
                  <FaTag className="benefit-icon" /> Exculsive samples from our partners!
                </li>
                <li>
                  <FaGift className="benefit-icon" /> Early access to new features and limited-time products!
                </li>
                <li>
                  <FaTruck className="benefit-icon" /> Rewards Streak!
                </li>
                <li>
                  <FaCalendar className="benefit-icon" /> Access to Secret Plus Day!
                </li>          
              </ul>
              <p>Benefits apply to eligible orders over the minimum subtotal only. Additional fees may apply. By selecting "Unlock $0 delivery", you agree that B-Street will automatically charge you at the then-current rate (currently $9.99/month) plus tax each month until you cancel your membership at any time (which you may do in-app or online prior to renewal date) and you also agree to the <span>Membership Terms and Cancellation Policy.</span></p>
            </div>
            <form 
              action={`${process.env.REACT_APP_SERVER_URL}/stripe/create-checkout-session-subscriptions`} 
              method="POST" 
              className="membership-container-right"
            >
              <input type="hidden" name="lookup_key" value="prod_QfbkfSqzDEGOry" />
              <button id="checkout-and-portal-button" type='submit'>Unlock ALL THIS! <br /> For $7.99</button>
            </form>
          </>
          :
          <MembershipActive />
        }
      </div>
    </div>
  )
}

export default BStreetPlus