import React from 'react'
import './footer.css'
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const Footer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
      <div className="footer">
        {isMobile ? null : (
          <div className="footer-container">
            <h1>B Street</h1>
          </div>
        )}
        <div className="footer-container">
          <div className="footer-column-one">
            <div className="column-one-wrapper column">
              <h2>Who Are We?</h2>
              <ul>
                <Link to="/about">
                  <li>About Us</li>
                </Link>
                <Link to="/careers">
                  <li>Careers</li>
                </Link>
                <Link to="/investor-relations">
                  <li>Investor Relations</li>
                </Link>
              </ul>
            </div>
            <div className="column-one-wrapper column">
              <h2>Interested in Partnering With B-Street?</h2>
              <ul>
                <Link to="/affiliate-program">
                  <li>Affiliate</li>
                </Link>
                <Link to="/partner-with-us">
                  <li>Are you a business owner?</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="footer-column-two">
            <div className="column-two-wrapper column">
              <h2>Stay Connected</h2>
            </div>
            <ul>
              <li>Instagram</li>
              <li>Twitter</li>
              <li>Facebook</li>
            </ul>
          </div>
          <div className="footer-column-three">
            <div className="column-three-wrapper">
              <Link to="/privacy-policy">
                <h2>Privacy Policy</h2>
              </Link>
              <Link to="/terms-of-use">
                <h2>Terms of use</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Footer