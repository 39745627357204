import { useState } from "react";
import axios from "axios";

const useFetchAddress = () => {
  const [addressForm, setAddressForm] = useState({});
  const [modal, setModal] = useState(false);
  const [address, setAddress] = useState("");
  const [phoneModal, setPhoneModal] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);

  const handlePhoneModal = () => {
    setPhoneModal(!phoneModal);
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressForm({
      ...addressForm,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/profile/address`,
        addressForm,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updateAddress = response.data.profile;
      setAddress(updateAddress);
    } catch (error) {
      console.error(
        "There was an error submitting your information. Please try again",
        error
      );
    }
    handleModal();
  };

  const handleSendVerification = async (e) => {
    e.preventDefault();
    const accountCredentials = addressForm.phoneNumber;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/sms/verify-code`,
        { accountCredentials },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.message === "Phone number verified") {
        setVerificationCodeSent(true);
        setErrorMessage(
          "Phone number verified. You can now create your account."
        );
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      setErrorMessage("Verification failed");
    }
  };

  const handleVerifyPhoneNumber = async (e) => {
    e.preventDefault();
    const accountCredentials = {
      createAccountPhoneNumber: addressForm.phoneNumber,
      verificationCode: addressForm.verificationCode,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/sms/verify-code`,
        { accountCredentials },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.message === "Phone number verified") {
        verificationCodeSent(true);
        setErrorMessage(
          "Phone number verified. You can now create your account."
        );
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      setErrorMessage("Verification failed");
    }
  };

  const handlePhoneNumberSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/profile/phoneNumber`,
        { addressForm },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedPhoneNumber = response.data.profile.phoneNumber;
      setPhoneNumber(updatedPhoneNumber);
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        if (error.response.status === 409) {
          setErrorMessage("Phone number already exists");
        } else {
          console.error("An error occurred:", error.response.data.message);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
    handlePhoneModal(false);
  };

  const fetchAddress = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/profile/address`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPhoneNumber(response.data.address);
      setAddress(response.data.address);
    } catch (error) {
      console.error("There was a problem fetching your profile", error);
    }
  };

  return {
    phoneNumber,
    handleSendVerification,
    handleVerifyPhoneNumber,
    errorMessage,
    handlePhoneNumberSubmit,
    handlePhoneModal,
    phoneModal,
    address,
    modal,
    handleModal,
    handleChange,
    handleSubmit,
    fetchAddress,
    verificationCodeSent,
  };
};

export default useFetchAddress;
