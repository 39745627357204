import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SignedInNavigation from "../navigation/SignedInNavigation";
import "./successful.css";
import io from "socket.io-client";

const socket = io.connect(process.env.REACT_APP_SERVER_URL);

const Successful = () => {
  const [clientAddress, setClientAddress] = useState("");
  const [restaurantAddress, setRestaurantAddress] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [hide, setHide] = useState(false);
  const [cart, setCart] = useState(null);
  const [orderInProgress, setOrderInProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [notificationStatus, setNotificationStatus] = useState(
    "Your order has been placed! Check your email for order updates and tracking."
  );

  const fetchLocationCreds = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        const clientLocation = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/profile/address`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPhoneNumber(clientLocation.data.address.phoneNumber);
        setClientAddress(clientLocation.data.address.address);

        const restaurantLocation = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/profile/cart`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCart(restaurantLocation.data);
        setRestaurantAddress(
          restaurantLocation.data.restaurantAddress[0].address
        );
        setRestaurantName(restaurantLocation.data.restaurant);
      } catch (error) {
        console.error("Error fetching location credentials:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const checkOrderInProgress = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/profile/order-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrderInProgress(response.data.orderInProgress);
    } catch (error) {
      console.error("There was an error fetching your order status");
    }
  };

  const activateOrderInProgress = async (token) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/profile/order-status`,
        { orderActive: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setOrderInProgress(response.data.orderActive);
      emailOrderNotification(token);
    } catch (error) {
      console.error("There was an error with updating the order status", error);
    }
  };

  const emailOrderNotification = async (token) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/stripe/send-order-notification`,
        { restaurantName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      updatePastOrders(token);
    } catch (error) {
      console.error(
        "There was an error sending the notification to the business owner",
        error
      );
    }
  };

  const updatePastOrders = async (token) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/profile/update/past-orders`,
        { cart, clientAddress },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      clearCart(token);
    } catch (error) {
      console.error("Error placing order notification:", error);
    }
  };

  const clearCart = async(token) => {
    try {
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}/profile/cart`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    } catch (error) {
        console.error("There was an error with clearing the cart", error)
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    } else {
      fetchLocationCreds();
      setHide(true);
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    checkOrderInProgress(token);
  }, []);

  useEffect(() => {
    console.log(cart, phoneNumber)
    if (cart && phoneNumber && !orderInProgress) {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No authToken found");
        return;
      }
      socket.emit("joinRoom", { cart });
      socket.emit("newOrder", { cart, token, clientAddress, phoneNumber });
      try {
        activateOrderInProgress(token);
      } catch (error) {
        console.error("Error updating past orders:", error);
      }
    }
  }, [cart, phoneNumber, orderInProgress]);

//   useEffect(() => {
//     socket.on("confirmedNotification", async (data) => {
//       try {
//         const token = localStorage.getItem("authToken");
//         await axios.patch(
//           `${process.env.REACT_APP_SERVER_URL}/profile/update/order-status`,
//           { data },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//       } catch (error) {
//         console.error(
//           "There was an error receiving confirmation update",
//           error
//         );
//       }
//       // setNotificationStatus(`Your order has been confirmed! We will notify you when its packaged and ready for delivery`);
//     });
//   }, []);

  //   useEffect(() => {
  //     socket.on("deliveryInProgress", (data) => {
  //         const trackUrl = data.data?.trackUrl;
  //         setNotificationStatus(
  //             <span>
  //                 Your order is being packaged and waiting to be shipped out. View your live order tracker:{" "}
  //                 <a href={trackUrl} target="_blank" rel="noopener noreferrer">
  //                     Click here
  //                 </a>
  //             </span>
  //         );
  //     });
  //     return () => {
  //         socket.off("deliveryInProgress");
  //     };
  //   }, []);

  useEffect(() => {
    socket.on("orderRejected", async (data) => {
      setNotificationStatus(
        `Your order has been canceled because ${data.reason}. #${data.orderNumber}`
      );
    });
  }, []);

  return (
    <div className="successful">
      {hide && <SignedInNavigation hide={hide} />}
      <div className="successful_container">
        {loading ? (
          <div className="loading-container">
            <img
              src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png"
              className="loading-icon"
              alt="the b street loading icon"
            />
          </div>
        ) : (
          <>
            <div className="the-order">
              <h1>{restaurantName}</h1>
              <p>
                {restaurantAddress.street} {restaurantAddress.city}{" "}
                {restaurantAddress.state} {restaurantAddress.zipcode}
              </p>
              <div className="order-details">
                <h2>Order Summary</h2>
                {cart &&
                  cart.items.length > 0 &&
                  cart.items.map((item) => (
                    <div className="order-details-items" key={item._id}>
                      <p>
                        {item.quantity}x {item.name}
                      </p>
                      <p>${(item.price * item.quantity).toFixed(2)}</p>
                    </div>
                  ))}
                <div className="order-details-total">
                  <span>Subtotal: </span>
                  <span>${cart && cart.totalPrice.toFixed(2)} </span>
                </div>
              </div>
              <div className="delivery-details">
                <h2>{notificationStatus}</h2>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Successful;
