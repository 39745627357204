import React, { useEffect } from 'react';
import './investorRelations.css';
import { useNavigate } from 'react-router-dom';
import Navigation from "../navigation/Navigation";
import { Helmet } from 'react-helmet';

const InvestorRelations = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <React.Fragment>
            <Navigation />
            <div className="investor-relations-container">
                <Helmet>
                    <title>B-Street Investor Relations</title>
                    <meta name="description" content="Learn about B-Street's commitment to transparency, sustainable growth, and responsible investing. Explore our financial highlights and communications." />
                </Helmet>
                <div className="hero-section">
                    <h1>Investor Relations</h1>
                    <p>
                        At B-Street, we believe in transparency and collaboration with our investors. 
                        Our mission is to empower local businesses and provide exceptional services 
                        to our community. Explore our commitment to sustainable growth and responsible investing.
                    </p>
                </div>

                <section className="company-overview">
                    <h2>Company Overview</h2>
                    <p>
                        B-Street is at the forefront of revolutionizing the local delivery landscape. 
                        With our innovative platform, we connect consumers with local businesses, 
                        enhancing convenience and accessibility. Our dedication to building lasting relationships 
                        with both customers and partners drives our growth and success.
                    </p>
                </section>

                <section className="financial-highlights">
                    <h2>Financial Highlights</h2>
                    <ul>
                        <li><strong>Year-over-Year Growth:</strong> 30% in 2023</li>
                        <li><strong>Customer Base:</strong> Over 50,000 active users</li>
                        <li><strong>Partnerships:</strong> 200+ local businesses</li>
                    </ul>
                </section>

                <section className="investor-communications">
                    <h2>Investor Communications</h2>
                    <p>
                        We are committed to keeping our investors informed with timely updates. 
                        Please subscribe to our newsletter or contact us directly for the latest 
                        company news, financial reports, and upcoming events.
                    </p>
                    <button className="subscribe-button">Subscribe to Newsletter</button>
                </section>

                <section className="contact-us">
                    <h2>Contact Us</h2>
                    <p>For inquiries related to investor relations, please reach out to our team:</p>
                    <p><strong>Email:</strong> contact@shopbstreet.com</p>
                    <p><strong>Phone:</strong> (646) 352-2512</p>
                </section>
            </div>
        </React.Fragment>
    );
};

export default InvestorRelations;