import React, { useEffect } from 'react';
import './careers.css';
import { useNavigate } from 'react-router-dom';
import Navigation from "../navigation/Navigation";
import { Helmet } from 'react-helmet';

const Careers = () => {
    const navigate = useNavigate();

    const handleApply = (position) => {
        const email = 'contact@shopbstreet.com';
        const subject = `Application for ${position}`;
        const body = `Dear B-Street Team,\n\nI am interested in the ${position} position and would like to apply.\n\nBest regards,\n[Your Name]`;

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink, '_blank');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <React.Fragment>
            <Navigation />
            <div className="careers-container">
                <Helmet>
                    <script type="application/ld+json">
                        {`
                        {
                            "@context": "https://schema.org",
                            "@type": "JobPosting",
                            "title": "Marketing Coordinator",
                            "description": "We’re seeking a creative marketing coordinator to help us build our brand presence.",
                            "identifier": {
                                "@type": "PropertyValue",
                                "name": "B-Street",
                                "value": "marketing-coordinator"
                            },
                            "hiringOrganization": {
                                "@type": "Organization",
                                "name": "B-Street",
                                "sameAs": "https://www.shopbstreet.com"
                            },
                            "jobLocation": {
                                "@type": "Place",
                                "address": {
                                    "@type": "PostalAddress",
                                    "addressLocality": "Remote"
                                }
                            },
                            "datePosted": "2024-11-15",
                            "employmentType": "Full-time",
                            "url": "https://www.shopbstreet.com/careers"
                        }
                        `}
                    </script>
                </Helmet>
                <div className="hero-section">
                    <h1>Join Our Team</h1>
                    <p>
                        At B-Street, we're always looking for passionate individuals who want to make a difference. 
                        Join us in creating a unique shopping experience that empowers local businesses and enhances 
                        community connections.
                    </p>
                </div>

                <section className="open-positions">
                    <h2>Open Positions</h2>

                    <div className="job-listing">
                        <h2>1. Marketing Coordinator</h2>
                        <p><strong>Location:</strong> Remote</p>
                        <p>
                            We’re seeking a creative marketing coordinator to help us build our brand presence. 
                            This role includes developing marketing campaigns, managing social media, and 
                            collaborating with local businesses.
                        </p>
                        <button className="apply-button" onClick={() => handleApply('Marketing Coordinator')}>
                            Apply Now
                        </button>
                    </div>

                    <div className="job-listing">
                        <h2>2. Software Engineer</h2>
                        <p><strong>Location:</strong> Hybrid</p>
                        <p>
                            We’re looking for a skilled software engineer to join our development team. 
                            You will work on enhancing our platform, developing new features, and ensuring 
                            a seamless user experience.
                        </p>
                        <button className="apply-button" onClick={() => handleApply('Software Engineer')}>
                            Apply Now
                        </button>
                    </div>

                    <div className="job-listing">
                        <h2>3. Customer Support Specialist</h2>
                        <p><strong>Location:</strong> On-site</p>
                        <p>
                            We need a friendly and dedicated customer support specialist to assist our users 
                            and help resolve inquiries. Your role will be crucial in maintaining our high standard 
                            of customer satisfaction.
                        </p>
                        <button className="apply-button" onClick={() => handleApply('Customer Support Specialist')}>
                            Apply Now
                        </button>
                    </div>
                </section>

                <section className="why-join">
                    <h2>Why Join B-Street?</h2>
                    <p>
                        Joining B-Street means being part of a passionate team dedicated to supporting local businesses 
                        and enhancing community life. We value innovation, collaboration, and a positive work culture. 
                        Enjoy competitive benefits, opportunities for growth, and the chance to make a real impact in your community.
                    </p>
                </section>
            </div>
        </React.Fragment>
    );
};

export default Careers;