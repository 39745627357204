import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './affiliateProgram.css';
import Navigation from '../navigation/Navigation';
import {Helmet} from 'react-helmet'

const AffiliateProgram = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    const emailSubject = 'Affiliate Program Application';
    const emailBody = 'Hello B-Street team,\n\nI am interested in joining the Affiliate Program. Here are my details:\n\nName: \nEmail: \nMessage: ';

    return (
        <React.Fragment>
            <Helmet>
                <title>B-Street Affiliate Program - Earn Commissions for Referrals</title>
                <meta name="description" content="Join the B-Street Affiliate Program and start earning money by promoting our local shopping platform. Earn commissions, access resources, and support local businesses." />
                <meta property="og:title" content="B-Street Affiliate Program" />
                <meta property="og:description" content="Become an affiliate with B-Street, earn commissions, and support local businesses. Join our community today!" />
                <meta property="og:url" content="https://shopbstreet.com/affiliate" />
            </Helmet>
            <Navigation />
            <div className="affiliate-program-container">
                <div className="hero-section">
                    <h1>Affiliate Program</h1>
                    <p>
                        Earn money by partnering with B-Street! Our Affiliate Program allows you to promote our services and earn commissions for every successful referral. Join us in making local shopping convenient and rewarding!
                    </p>
                </div>

                <section className="program-benefits">
                    <h2>Benefits of Joining Our Affiliate Program</h2>
                    <ul>
                        <li>Generous commission structure on every sale</li>
                        <li>Access to promotional materials and resources</li>
                        <li>Regular payouts and transparent tracking</li>
                        <li>Be part of a community that supports local businesses</li>
                    </ul>
                </section>

                <section className="how-it-works">
                    <h2>How It Works</h2>
                    <ol>
                        <li>Sign up for our Affiliate Program.</li>
                        <li>Receive your unique referral link.</li>
                        <li>Earn commissions for every customer who uses your link to make a purchase.</li>
                    </ol>
                </section>

                <section className="signup-info">
                    <h2>Express Your Interest!</h2>
                    <p>
                        Ready to start earning? Click the button below to send us an email and join our Affiliate Program.
                    </p>
                    <div className="apply-button-container">
                        <a 
                            href={`mailto:contact@shopbstreet.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
                            className="submit-button"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Apply Now
                        </a>
                    </div>
                </section>

                <section className="contact-info">
                    <h2>Have Questions?</h2>
                    <p>
                        Feel free to reach out to us for any inquiries regarding the Affiliate Program:
                    </p>
                    <p><strong>Email:</strong> contact@shopbstreet.com</p>
                    <p><strong>Phone:</strong> (646) 352-2512</p>
                </section>
            </div>
        </React.Fragment>
    );
};

export default AffiliateProgram;