import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Navigate } from "react-router-dom";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLISH_KEY);

const CheckoutForm = ({ cart, grandTotal }) => {
  const token = localStorage.getItem("authToken");
  const fetchClientSecret = useCallback(async () => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/stripe/create-checkout-session`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cart, grandTotal }),
      }
    );
    const data = await res.json();
    return data.clientSecret;
  }, [cart, grandTotal, token]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

const Return = () => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchSessionStatus = async () => {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get("session_id");

        const response = await fetch(`/session-status?session_id=${sessionId}`);
        const data = await response.json();

        setStatus(data.status);
      } catch (error) {
        console.error("There has been an error", error);
      }
    };

    fetchSessionStatus();
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }
  return null;
};

const StripePayment = ({ grandTotal, cart }) => {
  return (
    <div className="Checkout-form">
      <CheckoutForm grandTotal={grandTotal} cart={cart} />
      <Return />
    </div>
  );
};

export default StripePayment;
