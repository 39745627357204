import axios from 'axios'
import React, { useEffect, useState } from 'react'

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState()

  const fetchProfileData = async () =>{
    try {
      const token = localStorage.getItem('authToken');
      await axios.get(`${process.env.REACT_APP_SERVER_URL}/profile/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(resp => {
        const userFound = resp.data.userFound
        setUserInfo(userFound)
      })
    } catch (error) {
      console.error('There was an error retreiving your profile information', error)
    }
  };

  useEffect(()=>{
    fetchProfileData();
    window.scrollTo(0,0)
  },[])

  return (
    <div className='user-info'>
        <div className="user-info-container">
          <div className='user-info-container-flex'>
            {
              !userInfo ? 'Loading...' : <p>{userInfo.firstName + " " + userInfo.lastName}</p>
            }
          </div>
          <div className='user-info-container-flex'>
            {
              !userInfo ? 'Loading...' : <p>{userInfo.email}</p>
            }
            {/* <span onClick={handleEdit}>Edit</span> */}
          </div>
        </div>
    </div>
  )
}

export default UserInfo