import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './partnerWithUs.css';
import { useNavigate } from 'react-router-dom';
import Navigation from '../navigation/Navigation';

const PartnerWithUs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Partner With Us | B-Street</title>
        <meta name="description" content="Join B-Street as a partner and revolutionize local delivery services. Empower your business with increased customer reach, delivery solutions, and marketing support." />
        <meta name="keywords" content="B-Street partnership, local delivery, business growth, marketing support, partner benefits" />
      </Helmet>

      <Navigation />
      <div className="partner-with-us-container">
        <div className="hero-section">
          <h1>Interested in Partnering with B-Street?</h1>
          <p>
            Join us in revolutionizing local delivery services! At B-Street, we empower local businesses to connect with their customers seamlessly. Together, we can create a thriving community and elevate the shopping experience.
          </p>
        </div>

        <section className="benefits">
          <h2>Benefits of Partnering with Us</h2>
          <ul>
            <li>Increase your customer reach</li>
            <li>Boost your sales with our delivery services</li>
            <li>Gain access to marketing support and promotional opportunities</li>
            <li>Join a network of like-minded local businesses</li>
          </ul>
        </section>

        <section className="partner-form">
          <h2>Get Started</h2>
          <p>
            Here at B-Street, we welcome business owners of all sizes! Join us over at B Street Partners Blvd, and we'll talk more about becoming Partners <a href="https://b-street-partners.vercel.app" target="_blank" rel="noopener noreferrer">CLICK HERE</a>.
          </p>
        </section>

        <section className="contact-info">
          <h2>Contact Us</h2>
          <p>Have questions? Reach out to our partnership team:</p>
          <p>
            <strong>Email: </strong>
            <a target="_blank" rel="noopener noreferrer" href="mailto:contact@shopbstreet.com">
              contact@shopbstreet.com
            </a>
          </p>
          <p>
            <strong>Phone: </strong> (646) 352-2512
          </p>
        </section>
      </div>
    </React.Fragment>
  );
};

export default PartnerWithUs;
