import React, { useEffect, useRef, useState } from "react";
import SignedInNavigation from "../navigation/SignedInNavigation";
import "./restaurantModal.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaStar, FaHeart, FaPlusCircle } from "react-icons/fa";
import MenuItemModal from "./MenuItemModal";
import { FaRegHeart } from "react-icons/fa";
import RestaurantAbout from "./RestaurantAbout";

const RestaurantModal = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [restaurant, setRestaurant] = useState("");
  const [favoriteButton, setFavoriteButton] = useState(false);
  const [favoriteStatus, setFavoriteStatus] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [itemInCart, setItemInCart] = useState(false);
  const [loading, setLoading] = useState(true);
  const { name } = useParams();
  const navigate = useNavigate();
  const itemRef = useRef(null);

  const filteredBusinesses = restaurants.filter(
    (business) => business.businessName === name
  );

  const fetchBusinesses = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/restaurants`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRestaurants(response.data.restaurants);
    } catch (error) {
      console.error("Network error", error);
    }
  };

  const fetchFavoriteStatus = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/profile/favorites`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFavoriteStatus(response.data.favorites);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFavorite = async (restaurantId) => {
    const token = localStorage.getItem("authToken");
    try {
      if (favoriteButton) {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/profile/favorites`,
          { restaurantId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/profile/favorites`,
          { restaurantId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      fetchFavoriteStatus();
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      fetchBusinesses();
    }
  }, [navigate]);

  useEffect(() => {
    if (restaurants.length > 0) {
      fetchFavoriteStatus();
    }
  }, [restaurants]);

  useEffect(() => {
    if (filteredBusinesses.length > 0) {
      const updateFavoriteStatus = (restaurantId) => {
        const isFavorite = favoriteStatus.some(
          (fav) => fav.restaurantId === restaurantId
        );
        setFavoriteButton(isFavorite);
      };
      updateFavoriteStatus(filteredBusinesses[0]._id);
    }
  }, [favoriteStatus, filteredBusinesses]);

  const handleItemClick = (item, restaurant) => {
    setSelectedItem(item);
    setIsOpen(true);
    setRestaurant(restaurant);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenSearch = (toggle) => {
    setOpenSearch(toggle);
  };

  useEffect(() => {
    if (filteredBusinesses.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [filteredBusinesses]);
  return (
    <div className={`restaurant-modal ${isOpen ? "blur" : ""}`}>
      <SignedInNavigation
        setItemInCart={setItemInCart}
        itemInCart={itemInCart}
        handleOpenSearch={handleOpenSearch}
        openSearch={openSearch}
      />
      {loading ? (
        <div className={`loading-container ${loading ? "show" : ""}`}>
          <img
            src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png"
            className="loading-icon"
            alt="The B Street loading icon"
          />
        </div>
      ) : (
        <React.Fragment>
          <div className={`restaurant-modal ${isOpen ? "blur" : ""}`}>
            {filteredBusinesses.map((business) => (
              <div key={business._id} className="restaurant-modal-container">
                <img
                  src={business.iconPhoto}
                  alt={business.businessName}
                  className="restaurant-image"
                />
                <div className="restaurant-cuisines">
                  <p>{business.businessType}</p>
                </div>
                <div className="restaurant-name">
                  <h1>{business.businessName}</h1>
                  {favoriteButton ? (
                    <FaHeart
                      onClick={() => handleFavorite(business._id)}
                      className="restaurant-heart"
                      size={30}
                    />
                  ) : (
                    <FaRegHeart
                      onClick={() => handleFavorite(business._id)}
                      className="restaurant-heart"
                      size={30}
                    />
                  )}
                </div>
                <div className="restaurant-details">
                  <p className="business-phone">{business.businessPhone}</p>
                  <p className="eta">ETA: 45 min - 1 hr</p>
                  <p className="note">
                    (Orders can be placed outside of the business's operating
                    hours; however, fulfillment is not required until the
                    business is open.)
                  </p>
                </div>
                <RestaurantAbout filteredBusinesses={filteredBusinesses} />
              </div>
            ))}
            {filteredBusinesses.map((business) => (
              <div className="categories-flex" key={business._id}>
                <div className="categories-display-wrapper">
                  <div className="menu-display">
                    {Object.entries(
                      business.inventory
                        .filter((item) => item.stockStatus === "available")
                        .reduce((acc, item) => {
                          acc[item.category] = acc[item.category] || [];
                          acc[item.category].push(item);
                          return acc;
                        }, {})
                    ).map(([category, items], catIndex) => (
                      <div key={catIndex} className="menu-category">
                        <h2>{category}</h2>
                        <div className="menu-category-items">
                          {items.map((item, itemIndex) => (
                            <div key={itemIndex} className="menu-item">
                              {item.images
                                .slice(0, 1)
                                .map((image, imgIndex) => (
                                  <div
                                    ref={itemRef}
                                    className="menu-item-wrapper"
                                    key={imgIndex}
                                    onClick={() =>
                                      handleItemClick(
                                        item,
                                        filteredBusinesses[0].businessName
                                      )
                                    }
                                  >
                                    <div className="menu-item-price hover">
                                      <p>
                                        {item.name.length > 10
                                          ? item.name.slice(0, 15) + " ..."
                                          : item.name}
                                      </p>
                                      <span>$ {item.price}</span>
                                      <FaPlusCircle />
                                    </div>
                                    <div className="img-container">
                                      <img
                                        className="hover"
                                        src={image}
                                        alt={item.name}
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {isOpen && selectedItem && (
              <MenuItemModal
                setItemInCart={setItemInCart}
                filteredBusinesses={filteredBusinesses}
                item={selectedItem}
                onClose={handleCloseModal}
                restaurant={restaurant}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default RestaurantModal;
