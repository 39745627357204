import React, { useEffect } from "react";

const Address = ({
  errorMessage,
  handleVerifyPhoneNumber,
  handleSendVerification,
  handlePhoneModal,
  phoneModal,
  address,
  modal,
  phoneNumber,
  handleChange,
  handleSubmit,
  handleModal,
  verificationCodeSent,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="user-info">
      <div className="user-info-container">
        <div className="user-info-container-flex">
          <h2 onClick={handleModal}>+ Add a new address</h2>
        </div>
        <div>
          <h2>
            {address && address.address
              ? address.address.streetAddress
              : "No address added"}
          </h2>
        </div>

        {modal && (
          <div className="user-info-container-flex modal">
            <div className="modal-container">
              <div className="modal-header">
                <h2>New address</h2>
                <span onClick={handleModal}>X</span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-container-1">
                  <p>Street Address</p>
                  <input
                    required
                    onChange={handleChange}
                    placeholder="123-21 Ellan St"
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    aria-label="Street Address"
                  />
                </div>
                <div>
                  <p>Apt, suite, floor</p>
                  <input
                    onChange={handleChange}
                    placeholder="e.g 3D"
                    type="text"
                    name="addressDetails"
                    id="addressDetails"
                    aria-label="Address Details"
                  />
                </div>
                <div className="modal-container-2">
                  <div className="modal-container-2-inner">
                    <p>City</p>
                    <input
                      required
                      onChange={handleChange}
                      placeholder="e.g New York"
                      type="text"
                      name="city"
                      id="city"
                      aria-label="City"
                    />
                  </div>
                  <div className="modal-container-2-inner">
                    <p>State</p>
                    <input
                      required
                      onChange={handleChange}
                      placeholder="NY"
                      type="text"
                      name="state"
                      id="state"
                      aria-label="State"
                    />
                  </div>
                </div>
                <div>
                  <p>Zip code</p>
                  <input
                    required
                    onChange={handleChange}
                    placeholder="e.g 11422"
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    aria-label="Zip Code"
                  />
                </div>
                <button>Submit</button>
              </form>
            </div>
          </div>
        )}
      </div>

      <div className="user-info-container-flex phoneNumber-section">
        <div className="">
          <h2 onClick={handlePhoneModal}>+ Phone Number</h2>
        </div>
      </div>

      {phoneNumber && <h2>{phoneNumber.phoneNumber}</h2>}

      {phoneModal && (
        <div className="phone-modal">
          {!verificationCodeSent ? (
            <form onSubmit={handleVerifyPhoneNumber}>
              <span onClick={handlePhoneModal}>X</span>
              <input
                required
                onChange={handleChange}
                placeholder="e.g 6461119999"
                aria-label="Phone Number"
                minLength="10"
                maxLength="10"
                name="phoneNumber"
                id="phoneNumber"
                type="tel"
              />
              <p>
                By providing your phone number, you consent to receive text
                messages from B-Street related to your order. Standard message
                rates may apply. See our Terms of Use for more information.
              </p>
              <button type="submit">Send Code</button>
            </form>
          ) : (
            <form onSubmit={handleSendVerification}>
              <span onClick={handlePhoneModal}>X</span>
              <input
                required
                onChange={handleChange}
                aria-label="verification code"
                name="verificationCode"
                id="verificationCode"
                type="tel"
              />
              <p>
                Enter the verification code we sent to your phone. DO NOT share
                this code. Our Representatives will NEVER ask you for this
                number.
              </p>
              <button type="submit">Verify Code</button>
            </form>
          )}
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default Address;
