import React, { useEffect } from 'react';
import './about.css';
import { useNavigate } from 'react-router-dom';
import Navigation from '../navigation/Navigation';
import {Helmet} from 'react-helmet'

const About = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/main');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <React.Fragment>
            <Navigation />
            <div className="about-container">
                <Helmet>
                    <title>About B-Street: Connecting You with Local Businesses</title>
                    <meta name="description" content="Learn about B-Street's mission to connect you with local businesses and provide same-day shipping convenience." />
                    <meta property="og:title" content="About B-Street" />
                    <meta property="og:description" content="Connecting you with local businesses and supporting the community with same-day shipping." />
                </Helmet>
                <div className="hero-section">
                    <h1>B-Street</h1>
                    <p>
                        Same day shipping at your fingertips. <br /> At B-Street, we believe that convenience should never compromise quality. Our platform is designed to seamlessly connect you with a curated selection of local businesses, delivering everything from everyday essentials to luxury items—all at your fingertips.
                    </p>
                </div>

                <section className="mission">
                    <h2>Our Mission</h2>
                    <p>
                        Our mission is to empower local businesses and create a thriving community where convenience meets quality. We are committed to supporting small and diverse enterprises, offering them a platform to reach customers who value unique products and personalized service. Every purchase you make on B-Street contributes to the growth and sustainability of your community.
                    </p>
                </section>

                <section className="offerings">
                    <h2>What We Offer</h2>
                    <ul>
                        <li>Curated Selection: Handpicked local businesses ensuring high-quality items.</li>
                        <li>Seamless Experience: A user-friendly interface for effortless browsing and ordering.</li>
                        <li>Commitment to Sustainability: Partnering with businesses prioritizing eco-friendly practices.</li>
                        <li>Community Connection: Supporting local economies and fostering community relationships.</li>
                    </ul>
                </section>

                <div className="join-family">
                    <h2>Join the B-Street Family</h2>
                    <p>
                        Experience the luxury of convenience and the joy of supporting local businesses with B-Street. Explore our platform today and discover a world of endless possibilities right around the corner.
                    </p>
                    <button onClick={handleNavigate} className="explore-button">Explore Now</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default About;