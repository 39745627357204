import React, { useEffect, useState } from "react";
import "./signedInNavigation.css";
import { useNavigate } from "react-router-dom";
import useFetchAddress from "../customHooks/useFetchAddress";
import { Cloudinary } from "@cloudinary/url-gen/index";
import { useMediaQuery } from "react-responsive";
import SignedInMobile from "./SignedInMobile";
import axios from "axios";
import SignedInDesktop from "./SignedInDesktop";

const SignedInNavigation = ({
  setItemInCart,
  itemInCart,
  openSearch,
  handleOpenSearch,
  hide,
}) => {
  const [querySearchValue, setQuerySearchValue] = useState();
  const [searchInput, setSearchInput] = useState({ signedInSearch: "" });
  const { fetchAddress, address } = useFetchAddress();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [businesses, setBusinesses] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchInput({ ...searchInput, [name]: value });
  };

  const handleSubmitSearch = async (e) => {
    const searchValue = e.target.innerText || e.target.alt;
    setQuerySearchValue(searchValue);
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dtlf7hj7b",
    },
  });

  const logo = cld.image("B-Street_tfu6uv.png");

  const fetchBusinesses = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/restaurants`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBusinesses(response.data.restaurants);
    } catch (error) {
      console.error("There was an error fetching restaurants", error);
    }
  };

  const fetchCart = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/profile/cart`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.items.length > 0) {
        setItemInCart(true);
      }
    } catch (error) {
      console.error("There was an error fetching the cart", error);
    }
  };

  useEffect(() => {
    fetchAddress();
    fetchBusinesses();
    fetchCart();
  }, []);

  useEffect(() => {
    if (querySearchValue) {
      navigate(`/main/business/${querySearchValue}`);
    }
  }, [querySearchValue, navigate]);

  return (
    <header>
      {isMobile ? (
        <SignedInMobile
          itemInCart={itemInCart}
          handleSubmitSearch={handleSubmitSearch}
          querySearchValue={querySearchValue}
          handleChange={handleChange}
          openSearch={openSearch}
          searchInput={searchInput}
          handleOpenSearch={handleOpenSearch}
          businesses={businesses}
          hide={hide}
          address={address}
          logo={logo}
        />
      ) : (
        <SignedInDesktop
          itemInCart={itemInCart}
          handleSubmitSearch={handleSubmitSearch}
          querySearchValue={querySearchValue}
          handleChange={handleChange}
          openSearch={openSearch}
          searchInput={searchInput}
          handleOpenSearch={handleOpenSearch}
          businesses={businesses}
          hide={hide}
          address={address}
          logo={logo}
        />
      )}
    </header>
  );
};

export default SignedInNavigation;
