import React from 'react'
import { useNavigate } from 'react-router-dom';

const DeliveryDetails = ({ cart, handleSubmitInstruction, setRenderStripePayment, handleTip, address, handleDeliveryInstructions }) => {
    const navigate = useNavigate()
    const addressInfo = address.address;
    const profileInfo = address.fullName;
    const phoneNumber = address.phoneNumber;

    const handleReadyToPay = () => {
        setRenderStripePayment(true);
        window.scrollTo(0,0);
    };

    const handleNavigate = () => {
        navigate('/profile')
    }

    return (
        <div className='delivery-details'>
            <h1>Review and place delivery order</h1>
            {/* <form 
                action={`${process.env.REACT_APP_SERVER_URL}/stripe/create-checkout-session-subscriptions` }
                method="POST" 
                className="delivery-details-container"
            >
                <h2>TRY B-Street + TODAY!</h2>
                <p>Save when you shop with FREE delivery and even MORE perks!</p>
                <input type="hidden" name="lookup_key" value="prod_QfbkfSqzDEGOry" />
                <button aria-label="Unlock $0 Deliveries">Unlock $0 Deliveries</button>
            </form> */}
            <div className="delivery-details-container">
                <div className="delivery-details-container-upper">
                    <h2>Delivery, ASAP (25–40 min)</h2>
                    <span role="button" tabIndex={0}></span>
                </div>
                <div className="delivery-details-container-bottom">
                    <div className="bottom-flex">
                        {addressInfo ? (
                            <p>{`${addressInfo.streetAddress}, ${addressInfo.city}, ${addressInfo.state} ${addressInfo.zipCode}`}</p>
                        ) : 'Please add your address in order to place an order...'}
                        <p>{profileInfo}</p>
                        <p>{phoneNumber}</p>
                    </div>
                    <span onClick={handleNavigate} role="button" tabIndex={0}>Edit</span>
                </div>
            </div>
            <div className="delivery-details-container">
                <h2>Delivery Instructions</h2>
                <div className="delivery-container-flex">
                    <div className="bottom-flex">
                        <label htmlFor="contact-devlivery">
                            No contact delivery?
                        </label>
                        <select name="noContactDelivery" defaultValue='on' onChange={handleDeliveryInstructions}>
                            <option value="on">On</option>
                            <option value="off">Off</option>
                        </select>
                        <div className="delivery-instructions">
                            <textarea 
                                onChange={handleDeliveryInstructions}
                                name="deliverInstructions" 
                                id="deliver-instructions" 
                                placeholder='Add delivery instructinos for the driver'
                                aria-label="Delivery instructions for delivery"
                            />
                            <button onClick={handleSubmitInstruction}>Submit Instructions</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="delivery-details-container tip-container">
                <h2>Tip: select a dollar amount</h2>
                <div className="delivery-container-flex tip">
                    {[...Array(7)].map((_, index) => {
                        const amount = (index + 1).toFixed(2);
                        return (
                            <p key={amount} onClick={(amount) => handleTip(amount)} role="button" tabIndex={0}>
                                {amount}
                            </p>
                        );
                    })}
                </div>
            </div>

            <button onClick={handleReadyToPay} className='submit-order' disabled={!cart.items || !addressInfo || cart.items.length === 0} aria-label="Place order and proceed to payment">
                Ready to place order!
            </button>
        </div>
    );
};

export default DeliveryDetails;