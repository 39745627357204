// SuccessPage.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaTruck, FaTag, FaGift, FaHeadset, FaCalendar } from 'react-icons/fa';
import './subscription.css';
import SignedInNavigation from '../navigation/SignedInNavigation';
import axios from 'axios';

const SuccessPage = () => {
  const navigate = useNavigate();
 
  const handleProfile = () => {
    navigate('/profile');
  };

  const createNewPlusMember = async () => {
    const token = localStorage.getItem('authToken');
    try {
      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/profile/update/membership`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
    } catch (error) {
      console.error('There was an error updating your membership', error);
    }
  };

  useEffect(()=>{
    createNewPlusMember();
  },[])

  return (
    <div className="success-page">
      <SignedInNavigation />
      <div className="success-container">
        <FaCheckCircle className="success-icon" />
        <h1 className="success-title">Welcome to B-Street Plus!</h1>
        <p className="success-message">
          You've successfully subscribed to B-Street Plus. Get ready to enjoy premium benefits that enhance your delivery experience.
        </p>

        <div className="benefits-container">
          <h2 className="benefits-title">Your Exclusive Benefits:</h2>
          <ul className="benefits-list">
            <li>
              <FaTruck className="benefit-icon" /> Free delivery on all orders!
            </li>
            <li>
              <FaHeadset className="benefit-icon" /> Priority customer support for faster assistance!
            </li>
            <li>
              <FaTag className="benefit-icon" /> Exculsive samples from our partners!
            </li>
            <li>
              <FaGift className="benefit-icon" /> Early access to new features and limited-time products!
            </li>
            <li>
              <FaTruck className="benefit-icon" /> Every month you stay a Plus member you'll receive rewards points. Redeemable with participating Partners.
            </li>
            <li>
              <FaCalendar className="benefit-icon" /> Access to Secret Plus Day! (4 days throughout the year with HUGE discounts across the platform)
            </li>
          </ul>
        </div>

        <div className="success-actions">
          <button className="btn profile-btn" onClick={handleProfile}>
            Go to Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
