import React from 'react';
import { useNavigate } from 'react-router-dom';

const OrderInProgress = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/main")
  };
  return (
    <div className="order-in-progress">
      <h1>You Already Have An Order is in Progress!</h1>
      <p>Once that order is completed feel free to come back and continue shopping :)</p>
      <p>Check your email for updates on your order status!</p>
      <button className="home-button" onClick={handleRedirect}>
        Go to Home
      </button>
    </div>
  );
};

export default OrderInProgress;
