import React, { useCallback, useEffect, useState } from "react";
import SignedInNavigation from "../navigation/SignedInNavigation";
import NoBusinesses from "./NoBusinesses";
import { Link, useNavigate } from "react-router-dom";
import haversineDistance from "../haversineDistanceFormula";
import AddressPrompt from "./AddressPrompt";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./main.css";

const LetsEat = () => {
  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [botw, setBotw] = useState([]);
  const [coords, setCoords] = useState({
    lat: "",
    long: "",
  });
  const [loading, setLoading] = useState(true);
  const [itemInCart, setItemInCart] = useState(false);

  const handleOpenSearch = (toggle) => {
    setOpenSearch(toggle);
  };

  const handleNavLinks = (e) => {
    navigate(`/main/type/${e.target.innerText}`);
  };

  const fetchBusinessesNearby = useCallback(async (token) => {
    const radiusInMiles = 15;
    fetchBusinessesOfTheWeek(token);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/restaurants/nearby`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const businesses = response.data.businesses.filter(
        (business) =>
          business.businessAddress &&
          business.verificationStatus === "verified" &&
          business.inventory.length > 0
      );
      const nearbyBusinesses = businesses.filter((business) => {
        const distance = haversineDistance(
          response.data.lat,
          response.data.long,
          business.businessAddress.latitude,
          business.businessAddress.longitude
        );
        return distance <= radiusInMiles;
      });
      setCoords({ lat: response.data.lat, long: response.data.long });
      setRestaurants(nearbyBusinesses);
    } catch (error) {
      console.error("Network error", error);
    }
  }, []);

  const fetchBusinessesOfTheWeek = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/restaurants/botw`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const businesses = response.data.top10.filter(
        (business) =>
          business.businessAddress &&
          business.verificationStatus === "verified" &&
          business.inventory.length > 0
      );
      setBotw(businesses);
    } catch (error) {
      console.error(
        "There was a problem loading businesses of the week",
        error
      );
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/");
    } else {
      fetchBusinessesNearby(token);
      window.scrollTo(0, 0);
    }
  }, [fetchBusinessesNearby, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="letsEat">
      <Helmet>
        <title>B-Street | Shop Local, Delivered Quickly</title>
        <meta
          name="description"
          content="Explore top local stores and businesses on B-Street. Enjoy fast, reliable delivery for all your shopping needs."
        />
        <meta
          name="keywords"
          content="local stores, delivery, B-Street, shopping, fast delivery, local business, groceries, beauty products, electronics"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.shopbstreet.com" />
      </Helmet>
      <SignedInNavigation
        itemInCart={itemInCart}
        setItemInCart={setItemInCart}
        coords={coords}
        handleOpenSearch={handleOpenSearch}
        openSearch={openSearch}
      />
      {loading ? (
        <div className={`loading-container ${loading ? "show" : ""}`}>
          <img
            src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png"
            className="loading-icon"
            alt="The B Street loading icon"
          />
        </div>
      ) : botw.length > 0 && restaurants.length > 0 && coords ? (
        <div className={!openSearch ? "lets-eat-container" : " letsEatBlur "}>
          <div className="lets-eat-container-1">
            <div onClick={handleNavLinks} className="clothing-wrapper">
              <h2>clothing and apparel</h2>
            </div>
            <div
              onClick={handleNavLinks}
              className="beauty-and-skincare-wrapper"
            >
              <h2>beauty and skincare</h2>
            </div>
            <div onClick={handleNavLinks} className="home-goods-wrapper">
              <h2>home and living</h2>
            </div>
            <div onClick={handleNavLinks} className="handmade-wrapper">
              <h2>handmade goods</h2>
            </div>
            <div onClick={handleNavLinks} className="electronic-wrapper">
              <h2>electronics and gadgets</h2>
            </div>
            <div
              onClick={handleNavLinks}
              className="health-and-wellness-wrapper"
            >
              <h2>health and wellness</h2>
            </div>
            <div
              onClick={handleNavLinks}
              className="books-and-educational-wrapper"
            >
              <h2>books and educational</h2>
            </div>
            <div onClick={handleNavLinks} className="pets-wrapper">
              <h2>pets</h2>
            </div>
            <div
              onClick={handleNavLinks}
              className="jewlery-accessories-wrapper"
            >
              <h2>jewlery and accessories</h2>
            </div>
          </div>
          <ul className="lets-eat-container-2"></ul>
          <h1 className="lets-eat-wrapper-h1">Businesses of the week</h1>
          <div className="lets-eat-wrapper">
            <div className="lets-eat-container-3">
              {restaurants &&
                botw.map((rest) => (
                  <div
                    className="lets-eat-container-restaurants"
                    key={rest._id}
                  >
                    <div className="image">
                      <Link to={`/main/business/${rest.businessName}`}>
                        <img
                          src={rest.iconPhoto}
                          alt="The B Street loading icon"
                        />
                      </Link>
                      <div className="details">
                        <div className="inner">
                          <h2>{rest.businessName}</h2>
                          <h3>{rest.businessType}</h3>
                        </div>
                        <div>
                          <h2>{rest.estimatedDeliveryTime}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <h1 className="lets-eat-wrapper-h1">Exclusive Deal</h1>
          <div className="lets-eat-wrapper">
            <div className="lets-eat-container-3">
              {restaurants &&
                restaurants.map((rest) => (
                  <div
                    className="lets-eat-container-restaurants"
                    key={rest._id}
                  >
                    <div className="image">
                      <Link to={`/main/business/${rest.businessName}`}>
                        <img
                          src={rest.iconPhoto}
                          alt="The B Street loading icon"
                        />
                      </Link>
                      <div className="details">
                        <div className="inner">
                          <h2>{rest.businessName}</h2>
                          <h3>{rest.businessType}</h3>
                        </div>
                        <div>
                          <h2>{rest.estimatedDeliveryTime}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <h1 className="lets-eat-wrapper-h1">Closest to you</h1>
          <div className="lets-eat-wrapper">
            <div className="lets-eat-container-3">
              {restaurants &&
                [...restaurants]
                  .sort((a, b) => {
                    const distanceA = haversineDistance(
                      coords.lat,
                      coords.long,
                      a.businessAddress.latitude,
                      a.businessAddress.longitude
                    );
                    const distanceB = haversineDistance(
                      coords.lat,
                      coords.long,
                      b.businessAddress.latitude,
                      b.businessAddress.longitude
                    );
                    return distanceA - distanceB;
                  })
                  .map((rest) => (
                    <div
                      className="lets-eat-container-restaurants"
                      key={rest._id}
                    >
                      <div className="image">
                        <Link to={`/main/business/${rest.businessName}`}>
                          <img
                            src={rest.iconPhoto}
                            alt="The B Street loading icon"
                          />
                        </Link>
                        <div className="details">
                          <div className="inner">
                            <h2>{rest.businessName}</h2>
                            <h3>{rest.businessType}</h3>
                          </div>
                          <div>
                            <h2>{rest.estimatedDeliveryTime}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <h1 className="lets-eat-wrapper-h1">Picked for you</h1>
          <div className="lets-eat-wrapper">
            <div className="lets-eat-container-3">
              {restaurants &&
                restaurants.map((rest) => (
                  <div
                    className="lets-eat-container-restaurants"
                    key={rest._id}
                  >
                    <div className="image">
                      <Link to={`/main/business/${rest.businessName}`}>
                        <img
                          src={rest.iconPhoto}
                          alt="The B Street loading icon"
                        />
                      </Link>
                      <div className="details">
                        <div className="inner">
                          <h2>{rest.businessName}</h2>
                          <h3>{rest.businessType}</h3>
                        </div>
                        <div>
                          <h2>{rest.estimatedDeliveryTime}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <h1 className="lets-eat-wrapper-h1">Open Late / Night Owls</h1>
          <div className="lets-eat-wrapper">
            <div className="lets-eat-container-3">
              {restaurants &&
                restaurants
                  .filter((rest) => {
                    const opHours = rest.storeOperations.operatingHours;
                    return Object.values(opHours).some((hours) => {
                      const [closeHour] = hours.close.split(":").map(Number);
                      return closeHour >= 21;
                    });
                  })
                  .map((rest) => (
                    <div
                      className="lets-eat-container-restaurants"
                      key={rest._id}
                    >
                      <div className="image">
                        <Link to={`/main/business/${rest.businessName}`}>
                          <img
                            src={rest.iconPhoto}
                            alt="The B Street loading icon"
                          />
                        </Link>
                        <div className="details">
                          <div className="inner">
                            <h2>{rest.businessName}</h2>
                            <h3>{rest.businessType}</h3>
                          </div>
                          <div>
                            <h2>{rest.estimatedDeliveryTime}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <h1 className="lets-eat-wrapper-h1">Cheapest selections</h1>
          <div className="lets-eat-wrapper">
            <div className="lets-eat-container-3">
              {restaurants &&
                restaurants.map((rest) => (
                  <div
                    className="lets-eat-container-restaurants"
                    key={rest._id}
                  >
                    <div className="image">
                      <Link to={`/main/business/${rest.businessName}`}>
                        <img
                          src={rest.iconPhoto}
                          alt="The B Street loading icon"
                        />
                      </Link>
                      <div className="details">
                        <div className="inner">
                          <h2>{rest.businessName}</h2>
                          <h3>{rest.businessType}</h3>
                        </div>
                        <div>
                          <h2>{rest.estimatedDeliveryTime}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="all-stores">
            <div className="">
              <h1>All Stores</h1>
            </div>
            <div className="all-stores-wrapper">
              {restaurants
                ? restaurants.map((rest) => (
                    <div
                      className="lets-eat-container-restaurants"
                      key={rest._id}
                    >
                      <div className="image">
                        <Link to={`/main/business/${rest.businessName}`}>
                          <img
                            src={rest.iconPhoto}
                            alt="The B Street loading icon"
                          />
                        </Link>
                        <div className="details">
                          <div className="inner">
                            <h2>{rest.businessName}</h2>
                            <h3>{rest.businessType}</h3>
                          </div>
                          <div>
                            <h2>{rest.estimatedDeliveryTime}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      ) : coords.lat && restaurants.length === 0 ? (
        <NoBusinesses />
      ) : (
        <AddressPrompt />
      )}
    </div>
  );
};

export default LetsEat;
