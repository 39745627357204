import React, { useEffect, useState } from "react";
import Categories from "./Categories";
import SignedInNavigation from "../navigation/SignedInNavigation";
import { useNavigate } from "react-router-dom";
import useFetchAddress from "../customHooks/useFetchAddress";

const Profile = () => {
  const navigate = useNavigate();
  const [openSearch, setOpenSearch] = useState(false);
  const [itemInCart, setItemInCart] = useState(false)
  const {
    errorMessage,
    handlePhoneNumberSubmit,
    handlePhoneModal,
    phoneModal,
    address,
    modal,
    phoneNumber,
    handleChange,
    handleSubmit,
    handleModal,
    fetchAddress,
  } = useFetchAddress();

  const handleOpenSearch = (toggle) => {
    setOpenSearch(toggle);
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
    fetchAddress();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="profile">
      <SignedInNavigation
        setItemInCart={setItemInCart}
        itemInCart={itemInCart}
        address={address}
        handleOpenSearch={handleOpenSearch}
        openSearch={openSearch}
      />
      <Categories
        phoneNumber={phoneNumber}
        handlePhoneNumberSubmit={handlePhoneNumberSubmit}
        handlePhoneModal={handlePhoneModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        errorMessage={errorMessage}
        handleModal={handleModal}
        phoneModal={phoneModal}
        address={address}
        modal={modal}
      />
    </div>
  );
};

export default Profile;
