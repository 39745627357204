import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import EmptyCart from './EmptyCart';
import axios from 'axios';
import StripePayment from './StripePayment';
import { useMediaQuery } from 'react-responsive';

const Receipt = ({ 
        deliveryFee, 
        address, 
        renderStripePayment, 
        setRenderStripePayment, 
        tip, 
        setCart, 
        cart,
        orderInProgress
    }) => {
    const [cartTotal, setCartTotal] = useState(0);
    const [serviceFee, setServiceFee] = useState(0);
    const [tax, setTax] = useState(0);
    const [grandTotal, setGrandTotal] = useState('');
    const addressInfo = address.address;
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const handleRemoveItem = async (itemId) => {
        const token = localStorage.getItem('authToken');

        try {
            await axios.delete(`${process.env.REACT_APP_SERVER_URL}/profile/cart/${itemId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const updatedItems = cart.items.filter(item => item._id !== itemId);
            setCart({ ...cart, items: updatedItems });
        } catch (error) {
            console.error('There was an error', error);
        }
    };

    const handleReadyToPay = () => {
        setRenderStripePayment(true);
    };

    const calculateAdditionalFees = (total) => {
        const serviceFee = (0.3 + total * 0.02).toFixed(2);
        const tax = (total * 0.08875).toFixed(2);
        setServiceFee(Number(serviceFee));
        setTax(Number(tax));
    };

    useEffect(() => {
        if (cart && cart.items) {
            const total = cart.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
            setCartTotal(total);
            calculateAdditionalFees(total);
        }
    }, [cart]);

    useEffect(() => { 
        if(cart && serviceFee && tax) {
            setGrandTotal(cartTotal + serviceFee + tax + deliveryFee + tip);
        }
    }, [cart, cartTotal, serviceFee, tax, deliveryFee, tip]);
    
    return (
        <div className='receipt'>
            {
                renderStripePayment ? 
                <React.Fragment>
                    <StripePayment cart={cart} grandTotal={grandTotal} />
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="receipt-container first-row">
                        {
                            cart && cart.items && cart.items.length === 0 ?
                            null :
                            <React.Fragment>
                                <h2>Your order from </h2>
                                <span>{cart.restaurant}</span>
                            </React.Fragment>  
                        }
                        {
                            isMobile ? null : 
                            <React.Fragment>
                                <button disabled={!cart.items || !addressInfo || cart.items.length === 0} onClick={handleReadyToPay}>
                                    Ready to place order!
                                </button>
                                <p>By placing your order, you agree to B-Street's terms of use and privacy policy</p>
                            </React.Fragment>
                        }
                    </div>
                    {
                        cart.items && cart.items.length > 0 ? cart.items.map(item => (
                            <div className="receipt-container second-row" key={item._id}>
                                <p onClick={() => handleRemoveItem(item._id)}><FaTrash /></p>
                                <p>{item.quantity}x {item.name}</p>
                                <p>${(item.price * item.quantity).toFixed(2)}</p>
                            </div>
                        )) : <EmptyCart />
                    }
                    {
                        cart.items && cart.items.length > 0 ?
                        <div className="receipt-container third-row">
                            <div className="third-row-inner">
                                <p>Item Subtotal</p>
                                <p>${cartTotal.toFixed(2)}</p>
                            </div>
                            <div className="third-row-inner">
                                <p>Delivery Fee</p>
                                <p>${deliveryFee && deliveryFee.toFixed(2)}</p>
                            </div>
                            <div className="third-row-inner">
                                <p>Service Fees</p>
                                <p>${serviceFee && serviceFee.toFixed(2)}</p>
                            </div>
                            <div className="third-row-inner">
                                <p>Tax</p>
                                <p>${tax && tax.toFixed(2)}</p>
                            </div>
                            <div className="third-row-inner">
                                <p>Tip</p>
                                <p>{tip ? `$${tip.toFixed(2)}` : '$0.00'}</p>
                            </div>
                            <div className="third-row-inner">
                                <p>Total</p>
                                <p>${(cartTotal + serviceFee + tax + deliveryFee + (tip || 0)).toFixed(2)}</p>
                            </div>
                        </div> : ''
                    } 
                    <div className="receipt-container fourth-row">
                        <div className="total">
                            <p>Total: ${cartTotal ? (cartTotal + serviceFee + tax + deliveryFee + (tip || 0)).toFixed(2) : '0.00'}</p>
                        </div>
                        <div className="cart-redirect">
                            <Link to={'/main'}>
                                <FaArrowLeft />
                                <span>Back to store</span>
                            </Link>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    );
};

export default Receipt;
