export default function haversineDistance(lat1, lon1, lat2, lon2) {
    // console.log("l1:", lat1)
    // console.log("lo1:", lon1)
    // console.log("l2:", lat2)
    // console.log("lo2:", lon2)

    const R = 3959;
    const dLat = (lat2 - lat1) * (Math.PI / 180); 
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
    return R * c; 
}
