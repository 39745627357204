import React, { useState } from 'react';
import './profile.css';
import UserInfo from './UserInfo';
import Address from './Address';
import BStreetPlus from './BStreetPlus';
import PastOrders from './PastOrders';
import Favorites from './Favorites';
import ReferAFriend from './ReferAFriend';

const Categories = ({
  errorMessage,
  handlePhoneNumberSubmit,
  handlePhoneModal,
  phoneModal,
  phoneNumber,
  address,
  modal,
  handleChange,
  handleSubmit,
  handleModal
}) => {
  const [categorySelected, setCategorySelected] = useState('Profile');

  const handleSelected = (e) => {
    setCategorySelected(e.target.innerText);
  };

  const renderCategoryContent = () => {
    switch (categorySelected) {
      case 'Profile':
        return <UserInfo />;
      case 'Address & phone':
        return (
          <Address
            errorMessage={errorMessage}
            handlePhoneNumberSubmit={handlePhoneNumberSubmit}
            handlePhoneModal={handlePhoneModal}
            phoneModal={phoneModal}
            phoneNumber={phoneNumber}
            address={address}
            modal={modal}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleModal={handleModal}
          />
        );
      case 'B-Street+':
        return <BStreetPlus />;
      case 'Past orders':
        return <PastOrders />;
      case 'Favorites':
        return <Favorites />;
      case 'Refer':
        return <ReferAFriend />;
      default:
        return <UserInfo />;
    }
  };

  return (
    <div className="profile-categories">
      <ul className="profile-categories-container">
        <div className="categories">
          <li onClick={handleSelected}>Profile</li>
          <li onClick={handleSelected}>Address & phone</li>
          {/* <li onClick={handleSelected}>B-Street+</li> */}
          <li onClick={handleSelected}>Past orders</li>
          {/* <li onClick={handleSelected}>Refer</li> */}
          <li onClick={handleSelected}>Favorites</li>
        </div>
      </ul>
      <div className="profile-category">{renderCategoryContent()}</div>
    </div>
  );
};

export default Categories;
