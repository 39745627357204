import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { Cloudinary } from '@cloudinary/url-gen/index'
import React from 'react'

const SectionTwo = () => {

    const cld = new Cloudinary({
        cloud: {
            cloudName: 'dtlf7hj7b'
        } 
    });

    const clothingBrands = cld.image('7417708_gnzuhb.png');
    const skinCare = cld.image('10572581_zyjonx.png');
    const homeImage = cld.image('house_ejnvfx.png');
    const businesses = cld.image('3135727_zpeejj.png');

    clothingBrands.resize(scale().height(200).width(200))
    skinCare.resize(scale().height(200).width(200))
    homeImage.resize(scale().height(200).width(200))
    businesses.resize(scale().height(200).width(200))

  return (
    <div className='section-two'>
        <div className="section-two-container">
            <h1>Your Neighborhoods Most Trusted Delivery Service <br /> From</h1>
            <div className="section-two-container-inner">
                <div className="inner-content">
                    <AdvancedImage cldImg={clothingBrands}/>
                    <h2>Clothing Stores </h2>
                    <p>Everything you need, from everyday staples to your next favorite outfit!</p> 
                </div>
               <div className="inner-content">
                    <AdvancedImage cldImg={skinCare}/>
                    <h2>Beauty and Skincare</h2>
                    <p>Shop the latest / trending skincare and beauty essentials</p> 
               </div>
               <div className="inner-content">
                    <AdvancedImage cldImg={businesses}/>
                    <h2>Many Other Businesses</h2>
                    <p>From local gems to hidden treasures: Explore a variety of unique finds on B-Street!</p> 
               </div>
            </div>
            <h1>Right To</h1>
            <div className="section-two-container-inner">
                <div className="inner-content">
                    <AdvancedImage cldImg={homeImage}/>
                    <h2>Your Front Door</h2>
                    <p>The only finger you'll need to lift is the one to press order!</p> 
               </div>
            </div>
        </div>
    </div>
  )
}

export default SectionTwo