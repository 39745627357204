import { Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './landingPage/LandingPage';
import Login from './login/Login';
import CreateAccount from './createAccount/CreateAccount';
import Main from './main/Main';
import Profile from './profile/Profile';
import RestaurantModal from './restaurantModal/RestaurantModal';
import Cart from './cart/Cart';
import Footer from './footer/Footer';
import Successful from './successful/Successful';
import SubscriptionSuccess from './subscriptionForm/SubscriptionSuccess';
import BusinessCategoryMap from './businessCategoryMap/BusinessCategoryMap';
import About from './about/About';
import Careers from './careers/Careers';
import InvestorRelations from './investors/InvestorRelations';
import PartnerWithUs from './partnerWithUs/PartnerWithUs';
import AffiliateProgram from './affiliateProgram/AffiliateProgram';
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import TermsOfUse from './terms/TermsOfUse';
import Support from './support/Support';
import ResetPassword from './login/ResetPassword';
import VerifiyPasswordReset from './login/VerifiyPasswordReset';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<LandingPage />} />
        <Route path='/main' element={<Main />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/main/business/:name' element={<RestaurantModal />} />
        <Route path='/checkout' element={<Cart />} />
        <Route path='/create-account' element={<CreateAccount />} />
        <Route path='/return' element={<Successful />} />
        <Route path='/success' element={<SubscriptionSuccess />} />
        <Route path='/main/type/:category' element={<BusinessCategoryMap />} />
        <Route path='/about' element={<About />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/investor-relations' element={<InvestorRelations />} />
        <Route path='/partner-with-us' element={<PartnerWithUs />} />
        <Route path='/affiliate-program' element={<AffiliateProgram />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-use' element={<TermsOfUse />} />
        <Route path='/support' element={<Support />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/reset-password/:token' element={<VerifiyPasswordReset />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
