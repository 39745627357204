import React from "react";
import "./main.css";

const NoBusinesses = () => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h1 className="modal-title">No Businesses in Your Area Yet</h1>
        <p className="modal-text">
          We’re still a new and up-and-coming business, so we don’t have
          businesses available in every area just yet. But don’t worry, we’re
          working hard to expand and bring amazing local stores to your
          neighborhood soon. Stay tuned and thank you for your patience!
        </p>
      </div>
    </div>
  );
};

export default NoBusinesses;
