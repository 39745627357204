import axios from 'axios';
import React, { useState } from 'react'
import Navigation from '../navigation/Navigation';

const ResetPassword = () => {
    const [formData, setFormData] = useState({});
    const [responseMessage, setResponseMessage] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevVal => ({...prevVal, [name]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/profile/reset-password`, formData, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if(response.status === 200) {
                setFormData('');
                setResponseMessage(true);
            }
        } catch (error) {
            console.error("There was an error sendin the password reset link", error);
        }
    };

  return (
    <div className='login'>
        <Navigation />
        <div className="login-form">
            <form onSubmit={handleSubmit}>
                <h1>Reset Password</h1>
                <div className="login-form-password-container">
                    <p>Enter your email</p>
                    <input 
                        required 
                        onChange={handleChange} 
                        type="email" 
                        name="email" 
                        id="reset-password-email" 
                        className='userInput'
                        autoComplete="current-password" 
                    />
                </div>
                {
                    responseMessage ? 
                    <p>If the email is in our database you will receive an email with further instructions.</p> : null
                }
                <button>Submit</button>
            </form>
        </div>
    </div>
  )
}

export default ResetPassword