import React, { useEffect, useState } from 'react';
import './landingPage.css';
import Navigation from '../navigation/Navigation';
import { Link } from 'react-router-dom';
import SectionTwo from './partials/SectionTwo';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import {Helmet} from 'react-helmet';

const LandingPage = () => {
  const [loading, setLoading] = useState(true);

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'dtlf7hj7b',
    },
  });

  const mainImage = cld.image('landingPage.webp');

  useEffect(()=>{
    localStorage.clear();
  },[]);

  useEffect(()=>{
    setLoading(false)
  },[])

  return (
    <React.Fragment>
      {
        loading ? (
          <div className="loading-container">
            <img 
              src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png"
              className='loading-icon'
              alt="the b street loading icon"
            />
          </div>
        ) : 
        <div className='landingPage'>
          <Navigation />
          <div className="landingPage-container">
            <Helmet>
              <title>B-Street | Shop Local, Delivered Quickly</title>
              <meta name="description" content="B-Street connects local businesses with customers for quick, reliable deliveries. Join our platform today!" />
              <meta name="keywords" content="delivery, local business, convenience store, beauty supplies, B-Street" />
              <meta property="og:title" content="B-Street | Same day shipping for your local deliveries" />
              <meta property="og:description" content="B-Street connects local businesses with customers for quick, reliable deliveries. Join our platform today!" />
              <meta property="og:image" content="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png" />
              <meta property="og:url" content="https://www.shopbstreet.com" />
              <meta name="twitter:title" content="B-Street | Your Local Delivery Solution" />
              <meta name="twitter:description" content="B-Street connects local businesses with customers for quick, reliable deliveries. Join our platform today!" />
              <meta name="twitter:image" content="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png" />
          </Helmet>
            <div className="landingPage-container-left">
              <AdvancedImage cldImg={mainImage} />
            </div>
            <div className="landingPage-container-right">
              <p>All roads lead to this street</p>
              <div className="login-button">
                <Link to='/login' className='login-button'>Login /</Link> 
                <Link to='/create-account' className='login-button'> Sign Up</Link>
              </div>
            </div>
          </div>
          <SectionTwo />
        </div>
      }
    </React.Fragment>
  );
};

export default LandingPage;
