import React from 'react';
import { useNavigate } from 'react-router-dom';
import './main.css'; 

const AddressPrompt = () => {
  const navigate = useNavigate();

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  return (      
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Complete Your Profile</h2>
        <p className="modal-text">
          To see local businesses in your area, please add your address to your profile.
        </p>
        <button className="modal-button" onClick={handleGoToProfile}>
          Go to Profile
        </button>
      </div>
    </div>
  );
};

export default AddressPrompt;
