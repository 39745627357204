import React, { useEffect } from 'react';
import './termsOfUse.css';
import Navigation from "../navigation/Navigation"

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Navigation />
            <div className="terms-of-use-container">
                <section className="hero-section">
                    <h1>B-Street Terms of Use</h1>
                    <p>Effective Date: 10/27/24</p>
                </section>

                <h2>1. Acceptance of Terms</h2>
                <p>
                    By accessing or using the B-Street website, mobile application, or services (collectively, “Platform”), you agree to comply with and be bound by these Terms of Use and our Privacy Policy. If you do not agree, please discontinue using our Platform.
                </p>

                <h2>2. Eligibility</h2>
                <p>
                    To use our Platform, you must be at least 18 years old. By using the Platform, you represent and warrant that you are of legal age and have the authority to enter into these Terms.
                </p>

                <h2>3. Account Registration</h2>
                <p>
                    Users and businesses must register an account to access certain features. When you register, you agree to provide accurate, current, and complete information and keep it updated. You are responsible for the confidentiality of your account credentials and for all activities that occur under your account.
                </p>

                <h2>4. Use of Platform</h2>
                <h3>4.1 Users</h3>
                <ul>
                    <li>Users agree to use the Platform solely for personal, non-commercial purposes, in compliance with applicable laws and regulations.</li>
                </ul>

                <h3>4.2 Business Partners</h3>
                <ul>
                    <li>Business Partners may list products and services on B-Street and are responsible for the accuracy, quality, and safety of their offerings.</li>
                    <li>Business Partners agree to abide by our <a href="/partner-guidelines" className="link">Partner Guidelines</a> and ensure compliance with all relevant laws and regulations.</li>
                </ul>
                <h2>5. Prohibited Uses</h2>
                <p>
                    You agree not to misuse or interfere with the normal operation of the Platform. Prohibited activities include but are not limited to:
                </p>
                <ul>
                    <li>Using the Platform for illegal activities.</li>
                    <li>Distributing harmful malware or unauthorized access attempts to the Platform.</li>
                    <li>Engaging in deceptive, fraudulent, or abusive behavior on the Platform.</li>
                </ul>

                <h2>6. Termination</h2>
                <p>
                    We reserve the right to suspend or terminate your access to the Platform if you violate these Terms of Use, without prior notice or liability.
                </p>

                <h2>7. Disclaimers</h2>
                <p>
                    The Platform is provided "as is" and we make no warranties regarding the accuracy, reliability, or availability of the services offered. We disclaim any liability for damages resulting from your use or inability to use the Platform.
                </p>

                <h2>8. Limitation of Liability</h2>
                <p>
                    To the fullest extent permitted by law, B-Street's liability to you will be limited to the amount you paid for the service, if any, and we shall not be liable for any indirect, incidental, special, or consequential damages.
                </p>

                <h2>9. Indemnity</h2>
                <p>
                    You agree to indemnify and hold harmless B-Street, its affiliates, and partners from any claims, losses, or damages arising from your use of the Platform or violation of these Terms.
                </p>

                <h2>16. Contact Us</h2>
                <p>If you have any questions or concerns about these Terms, please contact us:</p>
                <ul>
                    <li>Email: <a href="mailto:contact@shopbstreet.com">contact@shopbstreet.com</a></li>
                    <li>Phone: (646) 352-2512</li>
                </ul>
            </div>
        </React.Fragment>
    );
};

export default TermsOfUse;
