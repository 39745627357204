import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const PastOrders = () => {
  const [data, setData] = useState();

  const fetchPastOrders = async () => {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/profile/past-orders`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setData(response.data.profileFound.pastOrders);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPastOrders();
  }, []);

  return (
    <div className="past-orders">
      <div className="past-orders-container">
        {data && data.length > 0 ? (
          <div className="past-orders-container-inner">
            {data.map((order) => (
              <div className="the-order" key={order._id}>
                <div className="order-date">
                  <h2>{order.orderDate.slice(0, 10)}</h2>
                </div>
                <img
                  src={order.businessDetails.image}
                  alt="business store front or logo"
                />
                <div className="order-restaurant">
                  <h2>{order.businessDetails.name}</h2>
                </div>
                <div className="">
                  <p>
                    {order.items.length} item for ${order.totalPrice}{" "}
                  </p>
                  {order.items.map((item) => (
                    <div key={item._id}>
                      <p>{item.itemName}</p>
                    </div>
                  ))}
                </div>
                <div className="order-buttons">
                  <Link
                    className="redirect-to-store"
                    to={`/main/business/${order.businessDetails.name}`}
                  >
                    Go to store
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Once you place an order it will show here</p>
        )}
      </div>
    </div>
  );
};

export default PastOrders;
