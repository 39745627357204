import React, { useEffect, useState } from "react";
import "./login.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Navigation from "../navigation/Navigation";

const Login = () => {
  const [formData, setFormData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      navigate("/main");
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/login`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("authToken", response.data.token);
      navigate("/main");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      console.error("There was an error submitting the form:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="login">
      <Navigation />
      <div className="login-form">
        <form onSubmit={(e) => handleSubmit(e)}>
          <h1>Sign in</h1>
          <div className="login-form-email-container">
            <p>Email</p>
            <input
              required
              autoComplete="username"
              onChange={handleChange}
              type="email"
              name="loginEmail"
              id="login-form-email"
              className="userInput"
            />
          </div>
          <div className="login-form-password-container">
            <p>Password</p>
            <input
              required
              onChange={handleChange}
              type="password"
              name="loginPassword"
              id="login-form-password"
              className="userInput"
              autoComplete="current-password"
            />
          </div>
          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          <div className="login-form-actions">
            <Link to="/reset-password">
              <p>Reset Password</p>
            </Link>
          </div>
          <div className="login-form-signIn-options">
            <button>{loading ? "Loading..." : "Sign In"}</button>
            <Link to="/create-account">
              <span>Create an account</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
