import axios from 'axios';
import React, { useEffect, useState } from 'react'

const ReferAFriend = () => {
  const [referralCode, setReferralCode] = useState('');

  const handleCopyCode = () => {
    navigator.clipboard.writeText(referralCode)
    .then(() => {
      alert("Referral code copied to clipboard!");   
    })
    .catch(err => {
      console.error('Failed to copy code: ', err);
    });
  }

  const fetchReferralCode = async() => {
    const token = localStorage.getItem('authToken');

    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/profile/referral`, {
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`
      }
    });
    setReferralCode(response.data.referralCode)
  };

  useEffect(()=>{
    window.scrollTo(0,0);
    fetchReferralCode();
  },[])

  return (
    <div className="refer">
      <div className="refer-container">
        <h1 className="refer-title">Invite a Friend, Get Rewarded!</h1>
        <p className="refer-description">
          Share your unique referral code with friends, and both of you will receive a promo when they make their first order.
        </p>
        
        <div className="promo-info">
          <div className="promo-item">
            <h2>You Get:</h2>
            <p><strong>$15 off</strong> on your next order</p>
          </div>
          <div className="promo-item">
            <h2>They Get:</h2>
            <p><strong>$15 off</strong> on their first order</p>
          </div>
        </div>

        <div className="refer-code-section">
          <h3>Your Referral Code</h3>
          <div className="refer-code-box">
            <input type="text" name="referInput" id="referInput" readOnly={true} value={referralCode} />
            <button className="copy-btn" onClick={handleCopyCode}>Copy Code</button>
          </div>
          <p className="refer-terms">
            *$25 minimum order required (excluding promotions) • Other fees and taxes may apply. 
          </p>
        </div>

        <div className="share-section">
          <h3>Share with Friends:</h3>
          <div className="share-buttons">
            <button className="share-btn email-btn">Invite via Email</button>
            <button className="share-btn sms-btn">Invite via SMS</button>
            <button className="share-btn social-btn">Share on Social Media</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferAFriend