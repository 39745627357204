import React, { useCallback, useEffect, useState } from "react";
import SignedInNavigation from "../navigation/SignedInNavigation";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./businessCategoryMap.css";
import { Helmet } from "react-helmet";

const BusinessCategoryMap = () => {
  const { category } = useParams();
  const [businesses, setBusinesses] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itemInCart, setItemInCart] = useState(false);

  const handleOpenSearch = (toggle) => {
    setOpenSearch(toggle);
  };

  const fetchCategory = useCallback(async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/restaurants`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const categoryFiltered = response.data.restaurants.filter(
        (business) =>
          business.businessType.toLowerCase() === category.toLowerCase()
      );
      setBusinesses(categoryFiltered);
    } catch (error) {
      console.error("Error fetching businesses:", error);
    }
  }, [category]);

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1100);
  }, [businesses]);

  return (
    <div className="business-category-map">
      <Helmet>
        <title>
          {category
            ? `${category} Businesses | B-Street`
            : "B-Street Businesses"}
        </title>
        <meta
          name="description"
          content={`Explore ${businesses.length} ${category} business(es) on B-Street. Find local businesses that offer the best services and products in ${category}.`}
        />
      </Helmet>

      <SignedInNavigation
        setItemInCart={setItemInCart}
        itemInCart={itemInCart}
        handleOpenSearch={handleOpenSearch}
        openSearch={openSearch}
      />

      {loading ? (
        <div className={`loading-container ${loading ? "show" : ""}`}>
          <img
            src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png"
            className="loading-icon"
            alt="The B Street loading icon"
          />
        </div>
      ) : (
        <React.Fragment>
          <h1>
            {businesses.length} {category} business(es) loaded
          </h1>
          <div className="business-list">
            {businesses.length > 0 ? (
              businesses.map((business) => (
                <Link
                  to={`/main/business/${business.businessName}`}
                  key={business._id}
                  className="business-item"
                >
                  <h2>{business.businessName}</h2>
                  <img
                    src={business.iconPhoto}
                    alt={`${business.businessName} logo`}
                  />
                  {business.businessAddress && (
                    <p>
                      Address: {business.businessAddress.streetAddress},{" "}
                      {business.businessAddress.city}
                    </p>
                  )}
                </Link>
              ))
            ) : (
              <p>No businesses found in this category</p>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default BusinessCategoryMap;
