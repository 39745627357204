import React from 'react'
import { FaFrown } from "react-icons/fa";

const EmptyCart = () => {
  return (
    <div className='empty-cart'>
        <div className="empty-cart-wrapper">
            <h1>Your cart is empty <FaFrown /> </h1>
        </div>
    </div>
  )
}

export default EmptyCart