import React, { useEffect, useState } from "react";
import "./restaurantModal.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const MenuItemModal = ({
  setItemInCart,
  item,
  onClose,
  restaurant,
  filteredBusinesses,
}) => {
  const [addToCart, setAddToCart] = useState(false);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [specialInstructions, setSpecialInstructions] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSpecialInstructions({
      ...specialInstructions,
      [name]: value,
    });
  };

  const handleAddToCart = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/profile/cart`,
        { item, quantity, specialInstructions, filteredBusinesses, restaurant },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setItemInCart(true);
      setAddToCart(true);
      setTimeout(() => {
        setAddToCart(false);
      }, 3000);
    } catch (error) {
      console.error(
        "There was a problem adding this to your cart. Please try again",
        error
      );
    }
  };

  const handleAddQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleMinusQuantity = () => {
    if (quantity >= 2) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, [navigate]);
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>{item.name}</h2>
        <div className="image-carousel">
          <Swiper
            modules={[Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={10}
            slidesPerView={1}
          >
            {item.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="carousel-image"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <p>{item.description}</p>
        <p>
          Price: <span>${item.price}</span>
        </p>
        <div className="item-instructions">
          <p>Special Instructions</p>
          <textarea
            name="specialInstructions"
            id="specialInstructions"
            placeholder="Add any requests here"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="modal-item-options">
          <div className="item-quantity">
            <span onClick={handleAddQuantity}>+</span>
            <p>{quantity}</p>
            <span onClick={handleMinusQuantity}>-</span>
          </div>
          <button onClick={handleAddToCart}>
            {" "}
            {addToCart ? "Added to cart!" : "Add to cart"}
            {addToCart ? undefined : <span> ${item.price * quantity}</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuItemModal;
