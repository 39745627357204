import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './privacyPolicy.css';
import Navigation from "../navigation/Navigation";
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <React.Fragment>
             <Helmet>
                <title>Privacy Policy | B-Street</title>
                <meta name="description" content="Read B-Street's Privacy Policy to understand how we collect, use, and protect your personal information when you use our services." />
                <meta name="keywords" content="Privacy Policy, B-Street, data protection, personal information, cookies, privacy rights" />
                <meta name="author" content="B-Street" />
            </Helmet>

            <Navigation />
            <div className="privacy-policy-container">
                <div className="hero-section">
                    <h1>Privacy Policy</h1>
                    <p>
                        Your privacy is important to us. This policy outlines how we collect, use, and protect your information.
                    </p>
                </div>

                <section className="policy-content">
                    <h1>B-Street Privacy Policy</h1>
                    <p><strong>Last Updated: 10/28/2024</strong></p>
                    <p>We are committed to protecting the privacy of our customers, partners, and visitors. This Privacy Policy explains how we collect, use, share, and safeguard your information when you access or use our platform at <a href="https://www.shopbstreet.com" target="_blank" rel="noopener noreferrer">https://www.shopbstreet.com</a> (the “Site”), mobile applications, and related services (collectively, the “Services”).</p>
                    <p>By using our Services, you agree to the collection and use of your information in accordance with this Privacy Policy. If you do not agree, please discontinue use of our Services.</p>

                    <h2>1. Information We Collect</h2>
                    <h3>1.1 Personal Information:</h3>
                    <p>When you register, place an order, or engage with our Services, we may collect the following personal information:</p>
                    <ul>
                        <li>Contact Information (email address, Name)</li>
                        <li>Contact Information (email address, phone number)</li>
                        <li>Payment Information (e.g., credit card details processed by third-party providers)</li>
                        <li>Address for delivery purposes</li>
                        <li>Business Information for partners (e.g., business name, address, tax identification number)</li>
                    </ul>

                    <h3>1.2 Non-Personal Information:</h3>
                    <p>We may also collect non-personal information such as:</p>
                    <ul>
                        <li>Browser type, device type, operating system, and IP address</li>
                        <li>Usage data, including pages visited, time spent, and actions taken on our Site</li>
                    </ul>

                    <h3>1.3 Cookies and Tracking Technologies:</h3>
                    <p>We use cookies and similar tracking technologies to enhance user experience, analyze trends, and improve our Services. You may modify cookie settings in your browser, but disabling cookies may affect your use of certain features.</p>

                    <h2>2. How We Use Your Information</h2>
                    <p>We use the information we collect for the following purposes:</p>
                    <ul>
                        <li>To Provide and Improve Services: To process orders, facilitate deliveries, and enhance customer experience.</li>
                        <li>To Communicate with You: To send order updates, promotional messages, and respond to inquiries.</li>
                        <li>For Security and Fraud Prevention: To protect our Services and detect unauthorized activities.</li>
                        <li>To Analyze Usage: To better understand how users engage with our platform and improve our offerings.</li>
                    </ul>

                    <h2>3. How We Share Your Information</h2>
                    <h3>3.1 Service Providers:</h3>
                    <p>We may share your information with trusted third-party service providers who assist us in providing our Services, including payment processors, delivery partners, and customer service providers.</p>

                    <h3>3.2 Business Partners:</h3>
                    <p>If you place an order with a partner business on our platform, we may share necessary information (e.g., delivery address, order details) to fulfill your order.</p>

                    <h3>3.3 Legal Obligations:</h3>
                    <p>We may disclose your information if required by law, such as in response to a subpoena, or to protect the rights, safety, or property of B-Street, our users, or others.</p>

                    <h3>3.4 Business Transfers:</h3>
                    <p>In the event of a merger, acquisition, or sale of assets, your information may be transferred to a third party. We will notify you of any such transfer and any changes to this Privacy Policy.</p>

                    <h2>4. Data Retention</h2>
                    <p>We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce our agreements. When information is no longer needed, we securely delete or anonymize it.</p>

                    <h2>5. Your Privacy Rights</h2>
                    <p>Depending on your location, you may have the following rights regarding your personal information:</p>
                    <ul>
                        <li>Access: Request access to the personal information we hold about you.</li>
                        <li>Correction: Request correction of any inaccurate or incomplete information.</li>
                        <li>Deletion: Request deletion of your personal information, subject to legal obligations.</li>
                        <li>Opt-Out: Opt-out of receiving promotional communications by following the unsubscribe instructions in our emails or contacting us.</li>
                    </ul>
                    <p>To exercise these rights, please contact us at <strong>contact@shopbstreet.com</strong>.</p>

                    <h2>6. Security</h2>
                    <p>We use commercially reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no online service is 100% secure, and we cannot guarantee absolute security. We encourage you to protect your login information and notify us immediately of any suspicious activity.</p>

                    <h2>7. Third-Party Links</h2>
                    <p>Our platform may contain links to third-party websites or services. This Privacy Policy does not apply to those third-party sites. We recommend reviewing the privacy policies of any external sites you visit.</p>

                    <h2>8. Children’s Privacy</h2>
                    <p>Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware of such data, we will delete it promptly.</p>

                    <h2>9. Changes to this Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time. When we do, we will revise the “Last Updated” date at the top. We encourage you to review this policy periodically for any updates. Continued use of our Services after any changes indicates acceptance of the revised policy.</p>

                    <h2>10. Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
                    <ul>
                        <li>Email: <strong>contact@shopbstreet.com</strong></li>
                    </ul>
                </section>
            </div>
        </React.Fragment>
    );
};

export default PrivacyPolicy;
