import React from 'react'
import './navigation.css'
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom'

const Navigation = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const cld = new Cloudinary({
        cloud: {
            cloudName: 'dtlf7hj7b'
        }
    });

    const logo = cld.image('B-Street_tfu6uv.png');
     
  return (
    <header>
        <div className='navigation'>
            <div className="navigation-top">
                {
                    isMobile ? null : <h2>Why leave the comfort of your home?</h2>
                }
                <Link to='/'><AdvancedImage  className='logo' cldImg={logo} /></Link>
            </div>
        </div>
    </header>
  )
}

export default Navigation