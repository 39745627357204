import React, { useEffect, useState } from "react";
import "./createAccount.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import Navigation from "../navigation/Navigation";
import { Helmet } from "react-helmet";

const CreateAccount = () => {
  const [accountCredentials, setAccountCredentials] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountCredentials({
      ...accountCredentials,
      [name]: value,
    });
  };

  const handleSendVerification = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/sms/send-verification`,
        accountCredentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setErrorMessage(response.data.message);
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to send verification code");
    }
  };

  const handleVerifyPhoneNumber = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/sms/verify-code`,
        { accountCredentials },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.message === "Phone number verified") {
        setIsVerified(true);
        setErrorMessage(
          "Phone number verified. You can now create your account."
        );
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      setErrorMessage("Verification failed");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    if (!isVerified) {
      setErrorMessage(
        "Please verify your phone number before creating an account."
      );
      return;
    }
    if (!passwordRegex.test(accountCredentials.createAccountPassword)) {
      setErrorMessage(
        "Password must be at least 8 characters long, include one special character, and one capital letter."
      );
      return;
    }
    if (
      accountCredentials.createAccountPassword !==
      accountCredentials.createAccountPasswordVerify
    ) {
      setErrorMessage("Passwords do not match");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/auth/create-account`,
          accountCredentials,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { token } = response.data;
        localStorage.setItem("authToken", token);
        navigate("/main");
      } catch (error) {
        setErrorMessage(error.response.data.message);
        console.error("There was an error while creating your account:", error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="createAccount">
      <Navigation />
      <div className="create-account-form">
        <Helmet>
          <title>B-Street | Shop Local, Delivered Quickly</title>
          <meta
            name="description"
            content="Create an account to join B-Street and experience unique delivery services."
          />
        </Helmet>
        <form onSubmit={handleSubmit}>
          <h1>Creating your account</h1>
          <div className="create-account-form-name">
            <div>
              <label htmlFor="firstName">First Name</label>
              <input
                onChange={handleChange}
                required
                type="text"
                name="firstName"
                id="firstName"
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label>
              <input
                onChange={handleChange}
                required
                type="text"
                name="lastName"
                id="lastName"
              />
            </div>
          </div>
          <div>
            <label htmlFor="createAccountEmail">Email</label>
            <input
              onChange={handleChange}
              required
              type="email"
              name="createAccountEmail"
              id="createAccountEmail"
            />
          </div>
          <div>
            <label htmlFor="createAccountPassword">Password</label>
            <input
              onChange={handleChange}
              required
              autoComplete="new-password"
              type="password"
              name="createAccountPassword"
              id="createAccountPassword"
              minLength="8"
            />
          </div>
          <div>
            <label htmlFor="createAccountPasswordVerify">Verify Password</label>
            <input
              onChange={handleChange}
              required
              autoComplete="new-password"
              type="password"
              name="createAccountPasswordVerify"
              id="createAccountPasswordVerify"
            />
          </div>
          <div>
            <label htmlFor="createAccountPhoneNumber">Phone Number</label>
            <input
              onChange={handleChange}
              placeholder="DO NOT INCLUDE COUNTRY CODE. e.g (999)-999-9999"
              type="tel"
              name="createAccountPhoneNumber"
              id="createAccountPhoneNumber"
              required
            />
          </div>
          {!isVerified && (
            <button
              type="button"
              id="verify-code-button"
              onClick={handleSendVerification}
            >
              Send Verification Code
            </button>
          )}
          {errorMessage === "Verification code sent" && (
            <div id="verify-code-modal">
              <label htmlFor="verificationCode">Verification code</label>
              <input
                onChange={handleChange}
                placeholder="Enter the code sent to your phone"
                type="tel"
                name="verificationCode"
                id="verificationCode"
                required
              />
              <button type="button" onClick={handleVerifyPhoneNumber}>
                Verify Number
              </button>
            </div>
          )}
          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          <div className="login-form-signIn-options">
            <button className="form-submit" disabled={!isVerified}>
              Create your account
            </button>
          </div>
          <p className="terms-and-agreement">
            By tapping “Create your account” you agree to B-Street's{" "}
            <Link to="/terms-and-conditions">Terms and Conditions</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy.</Link>
          </p>
          <h2>
            Have an account already? <Link to="/login">Sign in</Link>
          </h2>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;
